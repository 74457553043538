<template>
	<button
		:type="buttonType"
		:disabled="isDisabled"
		:class="[
			'relative transition-all duration-100 ease-in-out',
			{ 'cursor-progress' : isLoading && submit },
			{ 'uppercase' : uppercase },
			hoverColor,
			buttonWidth,
			buttonHeight,
			buttonClasses,
			bgColor,
			textColor,
			textClasses,
			focusColor,
			borderRadius,
			border
		]"
		:form="submit ? form : ''"
		@click="handleButtonClick"
	>
		<slot>
			<span
				:class="[
					!showSuccessText ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
					hasSuccessText ? 'duration-100' : 'duration-0',
					'absolute w-full transition-[opacity,scale] ease-in-out transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'
				]"
			>
				{{ buttonText }}
			</span>
			<span
				:class="[
					showSuccessText ? 'opacity-100 scale-100' : 'opacity-0 scale-0',
					hasSuccessText ? 'duration-100' : 'duration-0',
					'absolute w-full transition-[opacity,scale] ease-in-out transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'
				]"
			>
				{{ successText }}
			</span>

			<template v-if="isLoading">
				<LoadingSpinner
					:class="[
						borderRadius,
						bgColor,
						'absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-full h-full p-2 opacity-75'
					]"
				/>
			</template>
		</slot>
	</button>
</template>

<script setup lang="ts">
const props = defineProps({
	disabled: {
		type: Boolean,
		default: true
	},
	isLoading: {
		type: Boolean,
		default: false
	},
	buttonWidth: {
		type: String,
		default: 'w-full'
	},
	buttonHeight: {
		type: String,
		default: 'min-h-9'
	},
	buttonClasses: {
		type: String,
		default: 'px-3 shadow-sm'
	},
	loadingClasses: {
		type: String,
		default: ''
	},
	borderRadius: {
		type: String,
		default: 'rounded-lg'
	},
	focusClasses: {
		type: String,
		default: ''
	},
	buttonText: {
		type: String,
		default: 'Submit'
	},
	successText: {
		type: String,
		default: ''
	},
	successfulResponse: {
		type: Boolean,
		default: false
	},
	textClasses: {
		type: String,
		default: 'font-semibold text-sm'
	},
	uppercase: {
		type: Boolean,
		default: false
	},
	variant: {
		type: String,
		default: 'primary',
		validator: (value: string) => {
			return [ 'primary', 'secondary', 'secondary-outline', 'secondary-background', 'secondary-foreground', 'tertiary', 'tertiary-foreground', 'quarternary', 'custom', 'disabled' ].includes(value)
		}
	},
	submit: {
		type: Boolean,
		default: false
	},
	form: {
		type: String,
		default: ''
	},
	reset: {
		type: Boolean,
		default: false
	}
})
const { successfulResponse, successText, isLoading, disabled, variant, submit, reset, focusClasses, loadingClasses } = toRefs(props)

// Emits
const emit = defineEmits([ 'submit', 'click' ])

// Computed Vars
const bgColor = computed(() => {
	let color: string
	let loadingColor: string

	switch (variant.value) {
	case 'primary':
		color = 'bg-mx-orange dark:bg-mx-orange/80'
		loadingColor = 'bg-mx-orange/80 dark:bg-mx-orange/70'
		break
	case 'secondary':
		color = 'bg-white dark:bg-mx-green-800'
		loadingColor = 'bg-mx-gray-300 dark:bg-mx-green-600'
		break
	case 'secondary-background': // use when container is the default panel wrapper color (bg-mx-gray-100 dark:bg-mx-green-900)
		color = 'bg-white dark:bg-mx-green-800'
		loadingColor = 'bg-mx-gray-300 dark:bg-mx-green-600'
		break
	case 'secondary-foreground': // use when container is the default panel color (bg-white dark:bg-mx-green-700)
		color = 'bg-mx-gray-200 dark:bg-mx-green-800'
		loadingColor = 'bg-mx-gray-300 dark:bg-mx-green-600'
		break
	case 'secondary-outline':
		color = 'bg-white dark:bg-mx-green-800'
		loadingColor = 'bg-mx-gray-300 dark:bg-mx-green-600'
		break
	case 'tertiary':
		color = 'bg-mx-gray-200 dark:bg-mx-green-900'
		loadingColor = 'bg-mx-gray-300 dark:bg-mx-green-600'
		break
	case 'tertiary-foreground': // use when container is the dark panel color (bg-mx-gray-200 dark:bg-mx-green-800)
		color = 'bg-mx-gray-50 dark:bg-mx-green-900'
		loadingColor = 'bg-mx-gray-400 dark:bg-mx-green-600'
		break
	case 'quaternary':
		color = 'bg-white dark:bg-mx-green-900'
		loadingColor = 'bg-white dark:bg-mx-green-800'
		break
	case 'disabled':
		color = 'bg-mx-gray-100 dark:bg-mx-green-800'
		loadingColor = 'bg-mx-gray-300'
		break
	case 'custom':
		color = ''
		loadingColor = loadingClasses.value
		break
	default:
		color = 'bg-mx-orange dark:bg-mx-orange/80'
		loadingColor = 'bg-mx-orange/80 dark:bg-mx-orange/70'
	}

	if (!disabled.value && isLoading.value) {
		return loadingColor
	}
	return color
})

const hoverColor = computed(() => {
	let color: string

	switch (variant.value) {
	case 'primary':
		color = 'hover:bg-mx-orange/80 dark:hover:bg-mx-orange'
		break
	case 'secondary':
		color = 'hover:bg-mx-gray-100 dark:hover:text-mx-gray-100 dark:hover:bg-mx-green-900'
		break
	case 'secondary-outline':
		color = 'hover:bg-mx-orange hover:text-white dark:hover:bg-mx-orange dark:hover:text-white'
		break
	case 'secondary-background':
		color = 'hover:bg-mx-gray-50 dark:hover:bg-mx-green-700 dark:hover:text-white'
		break
	case 'secondary-foreground':
		color = 'hover:bg-mx-gray-100 dark:hover:bg-mx-green-900 dark:hover:text-white'
		break
	case 'tertiary':
		color = 'hover:bg-white dark:hover:bg-mx-green-700 dark:hover:text-white'
		break
	case 'tertiary-foreground':
		color = 'hover:bg-white dark:hover:bg-mx-green-600/80 dark:hover:text-white'
		break
	case 'quaternary':
		color = 'hover:bg-mx-gray-50 dark:hover:bg-mx-green-800 dark:hover:text-white'
		break
	case 'custom':
		color = ''
		break
	case 'disabled':
		color = 'cursor-not-allowed'
		break
	default:
		color = 'hover:bg-mx-orange/80 dark:hover:bg-mx-orange'
	}

	if (isDisabled.value) {
		color = 'cursor-not-allowed'
	}
	return color
})

const textColor = computed(() => {
	let color: string

	switch (variant.value) {
	case 'primary':
		color = 'text-white'
		break
	case 'secondary':
		color = 'text-black dark:text-mx-gray-300'
		break
	case 'secondary-outline':
		color = 'text-black dark:text-mx-gray-300'
		break
	case 'secondary-background':
		color = 'text-black dark:text-mx-gray-300'
		break
	case 'secondary-foreground':
		color = 'text-black dark:text-mx-gray-300'
		break
	case 'tertiary':
		color = 'text-black dark:text-mx-gray-300'
		break
	case 'tertiary-foreground':
		color = 'text-black dark:text-mx-gray-300'
		break
	case 'quaternary':
		color = 'text-black dark:text-mx-gray-50'
		break
	case 'custom':
		color = ''
		break
	case 'disabled':
		color = 'text-mx-gray-400'
		break
	default:
		color = 'text-white'
	}

	return color
})

const border = computed(() => {
	if (isDisabled.value) {
		return 'border-mx-gray-300 dark:border-mx-green-800'
	}

	let borderColor: string

	switch (variant.value) {
	case 'primary':
		borderColor = 'border-2 border-transparent'
		break
	case 'secondary':
		borderColor = 'border-2 border-mx-gray-100 dark:border-transparent'
		break
	case 'secondary-outline':
		borderColor = 'border-2 border-black dark:border-mx-gray-400 hover:border-mx-orange dark:hover:border-mx-orange'
		break
	case 'secondary-background':
		borderColor = 'border-2 border-white dark:border-transparent hover:border-mx-gray-50'
		break
	case 'secondary-foreground':
		borderColor = 'border-2 border-mx-gray-200 dark:border-transparent'
		break
	case 'tertiary':
		borderColor = 'border-2 border-mx-gray-200 dark:border-transparent'
		break
	case 'tertiary-foreground':
		borderColor = 'border-2 border-mx-gray-50 hover:border-white dark:border-transparent'
		break
	case 'quaternary':
		borderColor = 'border-2 border-mx-gray-300 dark:border-transparent'
		break
	case 'custom':
		borderColor = ''
		break
	case 'disabled':
		borderColor = 'border-2 border-transparent'
		break
	default:
		borderColor = 'border-2 border-transparent'
	}

	return borderColor
})

const focusColor = computed(() => {
	let color: string

	switch (variant.value) {
	case 'primary':
		color = 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-current focus:ring-mx-orange focus:ring-opacity-80 dark:ring-offset-mx-green-700'
		break
	case 'secondary':
		color = 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-current focus:ring-black focus:ring-opacity-80 dark:ring-offset-mx-green-700'
		break
	case 'secondary-outline':
		color = 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-current focus:ring-black focus:ring-opacity-80 dark:ring-offset-mx-green-700'
		break
	case 'secondary-background':
		color = 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-mx-gray-100 focus:ring-black dark:focus:ring-mx-gray-400 focus:ring-opacity-80 dark:ring-offset-mx-green-900'
		break
	case 'secondary-foreground':
		color = 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-current focus:ring-black dark:focus:ring-mx-gray-300 focus:ring-opacity-80 dark:ring-offset-mx-green-700'
		break
	case 'tertiary':
		color = 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-current focus:ring-black focus:ring-opacity-80 dark:ring-offset-mx-green-700'
		break
	case 'tertiary-forground':
		color = 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-current focus:ring-black dark:focus:ring-mx-gray-300 focus:ring-opacity-80 dark:ring-offset-mx-green-800'
		break
	case 'quaternary':
		color = 'focus:outline-none focus:ring focus:ring-offset-2 focus:ring-offset-current focus:ring-mx-black dark:focus:ring-mx-gray-400 focus:ring-opacity-80 dark:ring-offset-mx-green-700'
		break
	case 'custom':
		color = ''
		break
	case 'disabled':
		color = ''
		break
	default:
		color = 'focus:ring-mx-orange'
	}

	return focusClasses.value || color
})

const hasSuccessText = computed(() => {
	return successText.value.length
})
const showSuccessText = computed(() => {
	return successfulResponse.value && hasSuccessText.value
})
const isDisabled = computed(() => {
	return submit.value && disabled.value
})
const buttonType = computed(() => {
	if (reset.value) { return 'reset' }
	return submit.value ? 'submit' : 'button'
})

// Methods
function handleButtonClick (event: Event) {
	if (isLoading.value || isDisabled.value) {
		event.preventDefault()
		return
	}
	submit.value ? emit('submit', event) : emit('click', event)
}
</script>
